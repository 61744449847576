<template>
  <div class="smart-meter">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>
    <el-form :inline="true" class="smart-meter-form">
      <el-form-item label="房源">
        <el-select v-model="apartmentId" placeholder="不限" clearable style="width:175px">
          <el-option v-for="item in apartmentOption" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房号">
        <el-input v-model="roomNo" maxlength="24" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" round @click="onSearch('1')">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSave">保存</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" height="650px" style="width: 100%">
      <el-table-column prop="apartmentName" label="公寓-楼栋">
      </el-table-column>
      <el-table-column prop="roomNo" label="房号">
      </el-table-column>
      <el-table-column label="设备类型">
        <template slot-scope="scope">
          <div class='tempList_p'>
            <p style="margin-left: 10px" v-for="(it,idx) in scope.row.list" :key="idx">
              {{['电表','冷水表','热水表'][it.operateChargingModel-1]}}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="上次抄表时间">
        <template slot-scope="scope">
          <!-- {{(scope.row.list && scope.row.list[0])?scope.row.list[0].createDate:''}} -->
          {{scope.row.list[0].createDate || scope.row.list[1].createDate|| scope.row.list[2].createDate}}
        </template>
      </el-table-column>
      <el-table-column label="上次读数">
        <template slot-scope="scope">
          <div class='tempList'>
            <el-input v-for="(it,idx) in scope.row.list" maxlength="32" :key="idx" v-model="it.total"
              @blur="changeTable(scope.$index,idx,'1')" @keyup.native="keyUpChange(scope.$index,idx)"></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="本次读数">
        <template slot-scope="scope">
          <div class='tempList'>
            <el-input v-for="(it,idx) in scope.row.list" maxlength="32" :key="idx" v-model="it.lastTotal"
              @blur="changeTable(scope.$index,idx,'2')" @keyup.native="keyUpChange(scope.$index,idx)" placeholder="请输入">
            </el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="用量">
        <template slot-scope="scope">
          <div class='tempList_p tempList_yl'>
            <p style="margin-left: 10px" v-for="(it,idx) in scope.row.list" :key="idx">
              {{ it.consumption }}{{it.operateChargingModel==1?'度':'吨'}}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="抄表状态">
        <template slot-scope="scope">
          <div class='tempList_p'>
            <p style="margin-left: 10px" v-for="(it,idx) in scope.row.list" :key="idx"><span
                :style="it.state==1?'':'color:#FF0000'">{{it.state==1?'本月已抄表':'本月未抄表'}}</span></p>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <div class='tempList_p'>
            <p class="edit" v-for="(it,idx) in scope.row.list" :key="idx"
              @click="meterReadingRecord(scope.row.roomId,it.operateChargingModel)">抄表记录</p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[5, 10, 15, 20, 25]"
      :page-size="limit" :total="total"></el-pagination>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        title: "批量手动抄表 （普通水电表）",
        apartmentId: '',
        roomNo: '',
        apartmentOption: [],
        tableData: [],
        id: "",
        apartmentName: "",
        currentPage: 1,
        skip: 1,
        limit: 5,
        total: 0,
        beforeData: []
        //   roomIP: ""
        //   roomstatus: ""
      };
    },
    activated() {
      console.log("smart-meter activated!!");
      console.log(this.$route.params.id);
      this.getApartmentList();
      this.onSearch('1');
    },
    mounted() {

    },
    methods: {
      onSearch(flag) {
        //仅支持输入数字、汉字，大小写字母，限制字符长度1~24个字符
        let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,24}$/;
        if (this.roomNo && !reg.test(this.roomNo)) {
          this.$message({
            showClose: true,
            message: '房号格式不对',
            type: "error"
          });
          return;
        }
        if (flag == '1') {
          this.skip = 1;
          this.currentPage = 1;
          this.total = 0;
        }
        let dto = {
          pageNumber: this.skip,
          pageSize: this.limit,
          apartmentId: this.apartmentId,
          roomNo: this.roomNo,
        };
        this.post("/landlord-service/roomWaterElectric/getWebConsumption", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data.forEach((item, index) => {
            if (item.list) {
              item.list.forEach((it, idx) => {
                it.localLastTotal = it.lastTotal; //保存本次读数
              })
            }
          })
          this.total = res.data.total;
          this.tableData = res.data.data;
          this.cache.setLS("smartMeter", res.data.data);
        });
      },
      //获取房源列表
      getApartmentList() {
        var dto = {
          pageNumber: "1",
          pageSize: "99999",
          landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ?
            null :
            this.cache.getLS("userInfo")["userId"]
        };
        this.post("landlord-service/apartment/queryPagesApartment", dto).then(
          res => {
            let returnData = {};
            res.forEach(data => {
              returnData[data.id] = data.name;
            });
            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
            this.apartmentOption = res;
          }
        );
      },
      //改变表格展示条数
      handleSizeChange(val) {
        // this.skip = 1;
        this.limit = val;
        this.onSearch();
      },
      //表格翻页
      handleCurrentChange(val) {
        this.skip = val;
        this.onSearch();
      },
      onSave() {
        let _this = this;
        this.beforeData = this.cache.getLS("smartMeter");
        if (_this.validateTable() == false) {
          return;
        }
        let dto = [];
        _this.tableData.forEach((item, index) => {
          if (item.list) {
            item.list.forEach((it, idx) => {
              let obj = {};
              obj.operateChargingModel = it.operateChargingModel;
              obj.roomId = item.roomId;
              obj.total = it.total;
              obj.waterElectricId = it.waterElectricId;
              obj.id = it.id;
              obj.lastId = it.lastId;
              obj.lastTotal = it.lastTotal;
              if (it.total != _this.beforeData[index].list[idx].total) {
                obj.flag = 1
              } else {
                obj.flag = null
              }
              if (it.lastTotal != _this.beforeData[index].list[idx].lastTotal) {
                obj.lastFlag = 1
              } else {
                obj.lastFlag = null
              }
              dto.push(obj);
            })
          }
        })
        _this.post("/landlord-service/roomWaterElectric/meterReading", dto, {
          isUseResponse: true,
          operatorFlage: true,
          operateName: '手动抄表',
        }).then(
          res => {
            if (res.data.code == 0) {
              this.$message({
                message: "保存成功",
                type: "success"
              });
              _this.onSearch();
            }
          }
        );
      },
      validateTable() {
        let _this = this;
        let flag = true;
        this.tableData.forEach((item, index) => {
          if (item.list) {
            item.list.forEach((listIt, listId) => {
              if (listIt.total != null && listIt.lastTotal != null && (parseFloat(listIt.total) > parseFloat(
                  listIt.lastTotal))) {
                _this.$message({
                  showClose: true,
                  message: item.apartmentName + item.roomNo + '上次读数不能大于本次读数',
                  type: "error"
                });
                flag = false;
                return;
              }
              if (listIt.lastTotal == '0') {
                _this.$message({
                  showClose: true,
                  message: item.apartmentName + item.roomNo + '本次读数不能为0',
                  type: "error"
                });
                flag = false;
                return;
              }
            })
          }
        });
        return flag;
      },
      changeTable(idx, curr, flag) {
        let _this = this;
        this.tableData.forEach((item, index) => {
          if (item.list) {
            item.list.forEach((listIt, listId) => {
              if (listIt.total != null && listIt.lastTotal != null && (parseFloat(listIt.total) > parseFloat(
                  listIt.lastTotal))) {
                _this.$message({
                  showClose: true,
                  message: item.apartmentName + item.roomNo + '上次读数不能大于本次读数',
                  type: "error"
                });
                return false;
              }
              if (listIt.lastTotal == '0') {
                _this.$message({
                  showClose: true,
                  message: item.apartmentName + item.roomNo + '本次读数不能为0',
                  type: "error"
                });
                return false;
              }
            })
          }
          if (idx == index && item.list[curr].total != null && item.list[curr].lastTotal != null && (parseFloat(item
              .list[curr].total) <= parseFloat(item.list[curr].lastTotal))) {
            if (flag == '1' && item.list[curr].total != item.list[curr].localLastTotal) {
              item.list[curr].localLastTotal = item.list[curr].total;
            }
            if (flag == '2' && item.list[curr].localLastTotal && item.list[curr].lastTotal != item.list[curr]
              .localLastTotal) {
              item.list[curr].total = item.list[curr].localLastTotal;
            }
            item.list[curr].consumption = (Math.round((parseFloat(item.list[curr].lastTotal) - parseFloat(item.list[
              curr].total)) * 100) / 100).toFixed(2);
          }
        });
      },
      keyUpChange(idx, curr) {
        curr = curr.toString();
        this.tableData.forEach((item, index) => {
          if (idx == index) {
            item.list[curr].total = this.clearNoNum(item.list[curr].total.toString());
            item.list[curr].lastTotal = this.clearNoNum(item.list[curr].lastTotal.toString());
          }
        });
      },
      //控制只能输入数字和小数点后2位
      clearNoNum(value) {
        value = value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符   
        value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的   
        value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数  

        if (value.indexOf(".") < 0 && value != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
          value = parseFloat(value);
        }
        return value;
      },
      meterReadingRecord(roomId, operateChargingModel) {
        let _this = this;
        _this.$router.push({
          path: "/main/smartMeter/log/show/0",
          query: {
            roomId: roomId,
            operateChargingModel: operateChargingModel
          }
        });
      }
    }
  };
</script>
<style lang="scss">
  .el-table__row:hover {
    background-color: #ffffff !important;
  }

  .el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: #ffffff !important;
  }

  .tempList .el-input:not(:nth-child(1)) {
    margin-top: 10px !important;
  }

  .tempList_p p {
    line-height: 37px;
    margin: 0;
    padding: 0;
  }

  .tempList_p p:not(:nth-child(1)) {
    margin-top: 10px !important;
  }

  .el-input__inner {
    height: 35px;
    line-height: 35px;
  }

  .smart-meter .el-pagination {
    margin-top: 20px;
  }

  .smart-meter-form .el-form-item:last-child {
    float: right;
  }

  .tempList_p .edit {
    margin-left: 10px;
    color: #30BAC1;
    cursor: pointer;
  }
</style>